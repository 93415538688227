.card-container {
  width: 100%;
  min-height: 250px;
  margin-bottom: 3rem;
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 6px;
  background-color: #addec9;
}

.card-container a {
  font-family: "IBM Plex Sans", sans-serif, "Helvetica Neue", "Helvetica",
    "Arial", "sans-serif";
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
  line-height: 1;
  font-size: 1.1em;
  color: #fff;
  // color: #b7bdc6;
  line-height: 1.5;
}
.card-links {
  display: flex;
  justify-content: space-between;
  max-width: 250px;
  cursor: pointer;
  margin-top: 1em;
}

.card-container .card-links-all a:hover {
  color: #addec9;
}

// .card-links-featured {
//   // background-color: #22272e;
// }

// .card-links a:hover {
//   color: #212831;
// }

.card-container h4 {
  color: #fff;

  margin-bottom: 0.5em;
  line-height: 1.2;
}

.card-container-header {
  display: flex;
  justify-content: space-between;
}

// Small Mobile devices
@media (min-width: 0px) and (max-width: 599px) {
  .card-container {
    padding: 1rem;
  }
  .card-container h4 {
    font-size: 1.2em;
  }
  .card-container-header-text {
    width: 70%;
  }
  .card-container h4 {
    font-size: 1em;

    line-height: 1.5;
  }
}
