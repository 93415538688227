.blog-home-container {
  max-width: 100vw;
  display: flex;
}

.blog-home-content-container {
  width: 100%;
  padding-top: 4rem;
  display: flex;
  margin-left: 350px;
}

// Blog Content

.blog-home-content {
  width: 100%;
}

.blog-home-content-items {
  max-width: 800px;
  margin: auto;
}

.blog-home-featured-container {
  margin-top: 4rem;
  margin-bottom: 8rem;
}

.blog-home-featured-image {
  //   min-width: 200px;
  overflow: hidden;
  height: 400px;
  background-image: linear-gradient(
    to right,
    #212831 calc(50% - 100px),
    #232a35 50%,
    #212831 calc(50% + 100px)
  );
  background-size: 0;
  position: relative;
  overflow: hidden;
}
.blog-home-featured-image::before {
  content: "";
  position: absolute;
  z-index: -10;
  top: 0;
  right: 0;
  width: calc(200% + 200px);
  bottom: 0;
  background-image: inherit;
  animation: move 2s linear infinite;
  animation-iteration-count: 10;
}

.blog-home-featured-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-home-featured-description {
  width: 100%;
  margin-top: 2rem;
}

.blog-content-featured-tags {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  display: flex;
  flex-wrap: wrap;
}

// Articles

.blog-home-articles-container {
  margin-top: 4rem;
  display: flex;
}

.blog-home-articles-image {
  width: 450px;
  overflow: hidden;
  height: 190px;
  // background-color: #1b1f25;
  background-image: linear-gradient(
    to right,
    #212831 calc(50% - 100px),
    #232a35 50%,
    #212831 calc(50% + 100px)
  );
  background-size: 0;
  position: relative;
  overflow: hidden;
}

.blog-home-articles-image::before {
  content: "";
  position: absolute;
  z-index: -10;
  top: 0;
  right: 0;
  width: calc(200% + 200px);
  bottom: 0;
  background-image: inherit;
  animation: move 2s linear infinite;
  animation-iteration-count: 10;
}

@keyframes move {
  to {
    transform: translateX(calc(50% + 100px));
  }
}

.blog-home-articles-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-home-articles-description {
  width: 100%;
  min-height: 170px;
  margin-left: 2rem;
}

// Options Nav

.blog-home-options {
  width: 700px;
}

.blog-home-options-container {
  margin-top: 6rem;
  width: 70%;
}

// SideNav
.blog-home-sidenav {
  width: 350px;
  min-height: 100vh;
  position: fixed;
  padding-top: 2rem;
}

.blog-home-sidenav-container {
  width: 100%;
  margin: auto;
  margin-top: 2rem;
}

.blog-last {
  border-bottom: none;
}

.blog-home-sidenav-content {
  width: 80%;
  margin: auto;
  margin-bottom: 2rem;
}

.blog-sidenav-category-item {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 1rem;
}
.blog-sidenav-category-item a {
  color: #adbac7;
  margin-left: 0.5rem;
}

.blog-sidenav-category-item a:hover {
  color: #addec9;
}

.blog-sidenav-filters {
  display: flex;
  flex-wrap: wrap;
}

.see-all-blog {
  margin-top: 4rem;
  margin-bottom: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 2rem;
}

// Media Queries

@media (min-width: 0px) and (max-width: 599px) {
  .blog-home-container {
    width: 85%;
    margin: auto;
  }

  .blog-home-sidenav {
    display: none;
    width: 0px;
  }

  .blog-home-options {
    display: none;
    width: 0px;
  }

  .blog-home-content-container {
    margin-left: 0px;
  }
  .blog-home-content-items {
    max-width: 100%;
  }
  .blog-home-content {
    width: 100%;
  }
  .blog-home-articles-container {
    display: block;
  }

  .blog-home-articles-image {
    width: 100%;
    height: 250px;
  }
  .blog-home-articles-description {
    margin-top: 2rem;
    width: 100%;
    min-height: 170px;
    margin-left: 0rem;
  }

  .blog-home-featured-image {
    height: 250px;
  }
}

@media (min-width: 600px) and (max-width: 874px) {
  .blog-home-sidenav {
    display: none;
    width: 0px;
  }

  .blog-home-options {
    display: none;
    width: 0px;
  }

  .blog-home-content-container {
    margin-left: 0px;
  }
  .blog-home-content-items {
    max-width: 500px;
  }
  .blog-home-content {
    width: 100%;
  }
  .blog-home-articles-container {
    display: block;
  }

  .blog-home-articles-image {
    width: 100%;
    height: 300px;
  }
  .blog-home-articles-description {
    margin-top: 2rem;
    width: 100%;
    min-height: 170px;
    margin-left: 0rem;
  }

  .blog-home-featured-image {
    height: 300px;
  }
}

@media (min-width: 875px) and (max-width: 1279px) {
  .blog-home-sidenav {
    display: none;
    width: 0px;
  }

  .blog-home-options {
    display: none;
    width: 0px;
  }

  .blog-home-content-container {
    margin-left: 0px;
  }
  .blog-home-content-items {
    max-width: 750px;
  }
  .blog-home-content {
    width: 100%;
  }
}

@media (min-width: 1280px) and (max-width: 1679px) {
  .blog-home-sidenav {
    width: 300px;
  }

  .blog-home-options {
    display: none;
    width: 0px;
  }

  .blog-home-content-container {
    margin-left: 300px;
  }
  .blog-home-content-items {
    max-width: 800px;
  }
  .blog-home-content {
    width: 100%;
  }
}

@media (min-width: 1680px) and (max-width: 1820px) {
  .blog-home-sidenav {
    width: 300px;
  }

  .blog-home-options {
    width: 600px;
  }

  .blog-home-content-container {
    margin-left: 300px;
  }
  .blog-home-content-items {
    max-width: 750px;
  }
  .blog-home-content {
    width: 80%;
  }
}
