.loader {
  overflow: hidden;
  background-image: linear-gradient(
    to right,
    #2128317a calc(50% - 100px),
    #21283185 50%,
    #2128317a calc(50% + 100px)
  );

  background-size: 0;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
}

.loader::before {
  content: "";
  position: absolute;
  z-index: -10;
  top: 0;
  right: 0;
  width: calc(200% + 200px);
  bottom: 0;
  background-image: inherit;
  animation: move 2s linear infinite;
  animation-iteration-count: 10;
}

@keyframes move {
  to {
    transform: translateX(calc(50% + 100px));
  }
}
