// Blog Content

.blog-post-content-container {
  width: 100%;
  padding-top: 4rem;
  display: flex;
  margin-left: 350px;
}

.blog-post-content {
  width: 100%;
}

.blog-post-content-items {
  width: 800px;
  margin: auto;
}
.blog-sidenav-category-item a {
  color: #adbac7;
  margin-left: 1rem;
}

// Blog Post
// .blog-post-container {
//   //   margin-top: 4rem;
//   //   height: 600px;
// }

.blog-post-image {
  //   min-width: 200px;
  min-height: 400px;
  background-image: linear-gradient(
    to right,
    #2128317a calc(50% - 100px),
    #21283185 50%,
    #2128317a calc(50% + 100px)
  );
  background-color: #addec9;
  background-size: 0;
  position: relative;
  overflow: hidden;
}

.blog-post-image-item {
  //   min-width: 200px;
  overflow: hidden;
  min-height: 400px;
  background-image: linear-gradient(
    to right,
    #2128317a calc(50% - 100px),
    #21283185 50%,
    #2128317a calc(50% + 100px)
  );
  background-color: #000;
  background-size: 0;
  position: relative;
  overflow: hidden;
}

// .blog-post-image::before {
//   content: "";
//   position: absolute;
//   z-index: -10;
//   top: 0;
//   right: 0;
//   width: calc(200% + 200px);
//   bottom: 0;
//   background-image: inherit;
//   animation: move 2s linear infinite;
//   animation-iteration-count: 10;
// }

// @keyframes move {
//   to {
//     transform: translateX(calc(50% + 100px));
//   }
// }

.blog-post-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-post-detail {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.next-previous-post {
  margin-top: 2rem;
  margin-bottom: 6rem;
  display: flex;
  justify-content: space-between;
}

.next-previous-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

// Media Queries

@media (min-width: 0px) and (max-width: 599px) {
  .blog-post-content-container {
    margin-left: 0px;
  }
  .blog-post-content-items {
    max-width: 100%;
    margin: auto;
  }
  .blog-post-content {
    width: 100%;
  }

  .blog-home-articles-container {
    display: block;
  }

  .blog-post-image {
    width: 100%;
    min-height: 150px;
  }

  .blog-post-detail {
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 874px) {
  .blog-post-content-container {
    margin-left: 0px;
  }
  .blog-post-content-items {
    max-width: 85%;
    margin: auto;
  }
  .blog-post-content {
    width: 100%;
  }

  .blog-home-articles-container {
    display: block;
  }

  .blog-post-image {
    width: 100%;
    min-height: 300px;
  }

  .blog-post-detail {
    width: 100%;
  }
}

@media (min-width: 875px) and (max-width: 1279px) {
  .blog-post-content-container {
    margin-left: 0px;
  }
  .blog-post-content-items {
    max-width: 750px;
    margin: auto;
  }
  .blog-post-content {
    width: 100%;
  }
}
