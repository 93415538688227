.app-home-container {
  max-width: 100vw;
  display: flex;
}

.app-home-content-container {
  width: 100%;
  padding-top: 4rem;
  display: flex;
  margin-left: 350px;
}

// App Content

.app-home-content {
  width: 100%;
}

.app-home-content-items {
  width: 800px;
  margin: auto;
}

.app-home-featured-container {
  margin-top: 4rem;
}

// Options Nav

.app-home-options {
  width: 700px;
}

.app-home-options-container {
  margin-top: 6rem;
  width: 70%;
}

// SideNav
.app-home-sidenav {
  width: 350px;
  min-height: 100vh;
  position: fixed;
  padding-top: 2rem;
}

.app-home-sidenav-container {
  width: 100%;
  margin: auto;
  margin-top: 2rem;
}

.app-last {
  border-bottom: none;
}

.app-home-sidenav-content {
  width: 80%;
  margin: auto;
  margin-bottom: 2rem;
}

.app-sidenav-category-item {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-bottom: 1rem;
}
.app-sidenav-category-item a {
  color: #adbac7;
  margin-left: 0.5rem;
}

.app-sidenav-category-item a:hover {
  color: #539bf5;
}

.app-sidenav-filters {
  display: flex;
  flex-wrap: wrap;
}

.see-all-apps {
  margin-top: 4rem;
  margin-bottom: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

// Media Queries

@media (min-width: 0px) and (max-width: 599px) {
  .app-home-container {
    width: 85%;
    margin: auto;
  }

  .app-home-sidenav {
    display: none;
    width: 0px;
  }

  .app-home-options {
    display: none;
    width: 0px;
  }

  .app-home-content-container {
    margin-left: 0px;
  }
  .app-home-content-items {
    max-width: 100%;
  }
  .app-home-content {
    width: 100%;
  }
  .app-home-articles-container {
    display: block;
  }
}

@media (min-width: 600px) and (max-width: 874px) {
  .app-home-sidenav {
    display: none;
    width: 0px;
  }

  .app-home-options {
    display: none;
    width: 0px;
  }

  .app-home-content-container {
    margin-left: 0px;
  }
  .app-home-content-items {
    max-width: 500px;
  }
  .app-home-content {
    width: 100%;
  }
  .app-home-articles-container {
    display: block;
  }
}

@media (min-width: 875px) and (max-width: 1279px) {
  .app-home-sidenav {
    display: none;
    width: 0px;
  }

  .app-home-options {
    display: none;
    width: 0px;
  }

  .app-home-content-container {
    margin-left: 0px;
  }
  .app-home-content-items {
    max-width: 750px;
  }
  .app-home-content {
    width: 100%;
  }
}

@media (min-width: 1280px) and (max-width: 1679px) {
  .app-home-sidenav {
    width: 300px;
  }

  .app-home-options {
    display: none;
    width: 0px;
  }

  .app-home-content-container {
    margin-left: 300px;
  }
  .app-home-content-items {
    max-width: 800px;
  }
  .app-home-content {
    width: 100%;
  }
}

@media (min-width: 1680px) and (max-width: 1820px) {
  .app-home-sidenav {
    width: 300px;
  }

  .app-home-options {
    width: 600px;
  }

  .app-home-content-container {
    margin-left: 300px;
  }
  .app-home-content-items {
    max-width: 750px;
  }

  .app-home-content {
    width: 80%;
  }
}
