$nav-background: #1c1f23;
$text-color-light: #fff;

/* Title Fonts */
@font-face {
  font-family: "Dev-Title";
  font-style: normal;
  font-weight: 200;
  src: url("./assets//fonts/Uber\ Move\ Medium.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./assets/fonts/Uber\ Move\ Medium.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// Text
h1 {
  letter-spacing: normal;
  font-family: "Uber Move", sans-serif, "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  color: #ffffff;
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
  // line-height: 1.2;
}

h2 {
  letter-spacing: normal;
  font-family: "Uber Move", sans-serif, "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  font-weight: 800;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
  line-height: 1;
}

h4 {
  font-family: "Uber Move", sans-serif, "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  font-weight: 800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
  line-height: 1;
  font-size: 1.2em;
  color: #fff;
  // color: #b7bdc6;
  line-height: 1.5;
}

p {
  font-size: 1.1em;
  font-weight: 400;
  letter-spacing: normal;
  font-family: "Uber Move", sans-serif, "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  color: #fff;
  // color: #eaecef;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
  line-height: 1.5;
}

a {
  font-size: 1em;
  font-weight: 400;
  letter-spacing: normal;
  font-family: "Uber Move", sans-serif, "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0 0;
  margin: 0 0;
  line-height: normal;
  text-decoration: none;
}
a:hover {
  color: #addec9;
  text-decoration: underline;
  cursor: pointer;
}
.header-link-highlight:hover {
  color: #addec9;
  text-decoration: underline;
  cursor: pointer;
}

small {
  letter-spacing: normal;
  font-family: "Uber Move", sans-serif, "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";
  font-weight: 500;
  color: #999999;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0, 0;
  margin: 0, 0;
  line-height: 1;
  text-decoration: none;
  font-size: 0.8em;
}

body {
  background-color: #1c1f23;
}

//151922
//0f1216

// Loader
.bx--loading__stroke {
  stroke: #768390;
}

// Altassian

span.prismjs {
  background-color: none;
  background-color: #212831;
  border: 1px solid #2b323d;
  border-radius: 6px;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-right: 1rem;
}

// span.prismjs code span {
//   background-color: #212831;
// }

span.prismjs code:first-of-type {
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0;
  background-image: none;
}

.comment.linenumber.react-syntax-highlighter-line-number {
  color: rgb(92, 99, 112);
  font-size: 12px;
  margin-right: 10px;
}

// span.prismjs.css-1vdujjq {
//   background-color: none;
//   background-color: #212831;
// }

// span.prismjs.css-10fl9lg {
//   background-color: none;
//   background-color: #212831;
// }

// span.css-1vdujjq code:first-of-type {
//   background-image: none;
// }

// span.css-10fl9lg code:first-of-type {
//   background-image: none;
// }

// span.css-3yrc6a-CodeBlock {
//   background-color: none;
//   background-color: #212831;
// }

// span.css-1vdujjq code:first-of-type {
//   background-image: none;
// }

// span.css-3yrc6a-CodeBlock code:first-of-type {
//   background-image: none;
// }

// // span.css-1vdujjq {
// //   font-size: 1em;
// //   // font-family: "IBM Plex Mono", sans-serif;
// //   line-height: 1.5;
// //   font-weight: 400;
// // }

// // span.css-10fl9lg {
// //   font-size: 1em;
// //   // font-family: "IBM Plex Mono", sans-serif;
// //   line-height: 1.5;
// //   font-weight: 400;
// // }

.token {
  color: #fff;
}

span {
  color: #fff;
  font-size: 0.9em;
  font-weight: 400;
  letter-spacing: normal;
  font-family: "IBM Plex Mono";
}
