// Footer

.footer-component-container {
  margin-top: 6rem;
  min-height: 80px;
  padding-left: 100px;
  padding-right: 100px;
}

.footer-component-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-component-contact-section {
  margin-top: 2rem;
  justify-content: center;
  width: 20%;
  margin: auto;
}
.footer-component-contact-section-logo {
  margin-top: 0.8rem;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.footer-component-contact-section h4 {
  font-family: "display-text";
  font-size: 0.8rem;
  color: #addec9;
  margin-right: 0.5rem;
  margin-bottom: 0;
  text-align: center;
}

.footer-category-content {
  display: none;
}

// Media Queries
@media (min-width: 0px) and (max-width: 599px) {
  .footer-component-container {
    min-height: 300px;
    width: 100%;
    margin: auto;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 80px;
  }
  .footer-component-content {
    margin-top: 2rem;
    border-top: 1px solid #444c56;
  }

  .footer-component-contact-section {
    width: 100%;
  }
  .footer-component-contact-section-logo {
    width: 100%;
    flex-wrap: wrap;
    justify-content: left;
  }

  .footer-category-content {
    display: block;
    width: 100%;
  }

  .footer-section-contact {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 600px) and (max-width: 874px) {
  .footer-component-container {
    min-height: 300px;
    width: 100%;
    margin: auto;
    padding-bottom: 80px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .footer-component-content {
    margin-top: 2rem;
    border-top: 1px solid #444c56;
  }

  .footer-component-contact-section {
    width: 100%;
  }
  .footer-component-contact-section-logo {
    width: 100%;
    flex-wrap: wrap;
    justify-content: left;
  }

  .footer-category-content {
    display: block;
    width: 100%;
  }

  .footer-section-contact {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 875px) and (max-width: 1279px) {
  .footer-component-container {
    min-height: 300px;
    width: 100%;
    margin: auto;
    padding-bottom: 80px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .footer-component-content {
    margin-top: 2rem;
    border-top: 1px solid #444c56;
  }

  .footer-category-content {
    display: block;
    width: 100%;
  }
}
