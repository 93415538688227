.tags-container {
  //   min-width: 50px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: auto;
  background-color: #212831;
  border-radius: 5px 5px 5px 5px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.tag-color-generic {
  color: #768390;
}

.tag-color-blue {
  color: #539bf5;
}
.tag-color-green {
  color: #05aa82;
}

.tag-color-red {
  color: #e5534b;
}
.tag-color-purple {
  color: #986ee2;
}

.tag-color-yellow {
  color: #daaa3f;
}

// App
.app-tag-container {
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: auto;
  margin-right: 1rem;
}

.app-dot {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #768390;
  margin-right: 0.3rem;
}

.app-color-Python {
  background-color: #6bc46d;
}

.app-color-Javascript {
  background-color: #e5534b;
}
.app-color-Go {
  background-color: #986ee2;
}
