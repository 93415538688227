// Home Page

.home-container {
  max-width: 100vw;
  padding-left: 100px;
  padding-right: 100px;
  min-height: 100vh;
}

.home-content {
  flex-basis: 100%;
  padding: 5rem;
  padding-top: 10rem;
}

.home-sections {
  width: 65%;
  margin: auto;
}

// Landing Page
.landing-content {
  width: 100%;
  height: 75vh;
  padding-top: 20vh;
}

.landing-content h1 {
  font-size: 8rem;
  font-family: "Dev-Title";
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #fff;
}

.landing-content h2 {
  font-size: 1.5rem;
  font-family: "Dev-Title", sans-serif;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #addec9;
}

.landing-content h4 {
  margin-bottom: 1rem;
  line-height: 1.5;
  font-size: 1.2em;
  font-weight: 500;
}

.contact-section {
  margin-top: 4rem;
  border-radius: 6px;
}
.contact-section-logo {
  display: flex;
  justify-content: space-between;
  max-width: 130px;
  cursor: pointer;
}

.contact-section-logo-item {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.contact-section-logo a {
  font-family: "Uber Move", sans-serif;
  font-size: 0.8rem;
  color: #afafaf;
  margin-left: 0.5rem;
}

.contact-section-logo a:hover {
  color: #05aa82;
}

// Section
.section-container {
  margin-top: 8rem;
}

.section-divider {
  // display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 6rem;
}

.section-title {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;
}

.section-title h4 {
  //   color: #fff;
  font-family: "Uber Move", sans-serif;
  font-weight: 600;
  color: #fff;
  font-size: 2rem;
  margin-bottom: 0;
  margin-left: 2rem;
}

.section-divider-line {
  border-top: 0.1px solid #addec9;
  width: 100%;
}

// About Content

.about-content {
  margin-top: 4rem;
}

.about-content-experience {
  display: flex;
  justify-content: space-between;
}

.about-content-technologies {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}

.about-content-title {
  flex-basis: 25%;
  max-width: 200px;
  padding-right: 2rem;
}

.about-content-experience-container {
  flex-basis: 75%;
}

.about-content-technologies-container {
  flex-basis: 75%;
  display: grid;
  grid-template-columns: 49% 49%;
  row-gap: 1rem;
  column-gap: 1rem;
}

.about-technologies-item-container {
  min-height: 65px;
  // border: 1px solid #2b323d;
  // border-radius: 5px;
  padding: 1em;
  // background-color: #1f242c;
}

.about-technologies-item {
  display: flex;
  align-items: center;
  padding-top: 0.5em;
  padding-bottom: 1em;
}

.about-technologies-list {
  margin-left: 3rem;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  flex-wrap: wrap;
}

.about-content-experience-grid {
  margin-top: 2rem;
}

.about-content a {
  color: #addec9;
}
// Blog Content

.blog-content {
  margin-top: 4rem;
}

.blog-content-featured {
  display: flex;
  justify-content: space-between;
}

.blog-content-featured-title {
  flex-basis: 25%;
  max-width: 200px;
  padding-right: 2rem;
}

.blog-content-featured-container {
  flex-basis: 75%;
}

.blog-content-featured-container-item {
  // display: flex;
  margin-bottom: 8rem;
  // min-height: 200px;
}

.blog-content-featured-image {
  flex-basis: 40%;
  width: 450px;
  overflow: hidden;
  height: 190px;
  background-image: linear-gradient(
    to right,
    #212831 calc(50% - 100px),
    #232a35 50%,
    #212831 calc(50% + 100px)
  );
  background-size: 0;
  position: relative;
  overflow: hidden;
}

.blog-content-featured-image::before {
  content: "";
  position: absolute;
  z-index: -10;
  top: 0;
  right: 0;
  width: calc(200% + 200px);
  bottom: 0;
  background-image: inherit;
  animation: move 2s linear infinite;
  animation-iteration-count: 10;
}

@keyframes move {
  to {
    transform: translateX(calc(50% + 100px));
  }
}

.blog-content-featured-description {
  flex-basis: 100%;
  min-width: 200px;
  margin-left: 0rem;
  margin-top: 2rem;
}

.blog-content-featured img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-content-featured-logo {
  display: flex;
  margin-top: 1rem;
}

.see-all-container {
  // margin-top: 6rem;
  // margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.see-all-container h4 {
  color: #539bf5;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.see-all-container a {
  font-family: "Uber Move", sans-serif;
  font-weight: 800;
  font-size: 1em;
  margin-right: 0.5rem;
}
.see-all-container-logo-small {
  display: none;
}
.see-all-container-logo-large {
  display: block;
}

//Blog Categories

.blog-categories {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}

.blog-categories-title {
  flex-basis: 25%;
  max-width: 200px;
  padding-right: 2rem;
}

.blog-categories-container {
  flex-basis: 75%;
  display: grid;
  grid-template-columns: 49% 49%;
  row-gap: 1rem;
  column-gap: 1rem;
}

.blog-category-item {
  // border-bottom: 1px solid #2b323d;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

.blog-category-item h4 {
  margin-left: 1.5rem;
  cursor: pointer;
}

.blog-category-item h4:hover {
  color: #addec9;
}

// .blog-category-item:nth-child(3) {
//   border-bottom: none;
// }
.blog-category-item:last-child {
  border-bottom: none;
}

// Apps
.app-content {
  margin-top: 4rem;
}

.app-content-featured {
  display: flex;
  justify-content: space-between;
}

.app-content-featured-title {
  flex-basis: 25%;
  max-width: 200px;
  padding-right: 2rem;
}

.app-content-featured-container {
  flex-basis: 75%;
}

//App Categories

.app-categories {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
}

.app-categories-title {
  flex-basis: 25%;
  max-width: 200px;
  padding-right: 2rem;
}

// Other Apps

.app-others-container {
  flex-basis: 75%;
  display: grid;
  grid-template-columns: 49% 49%;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-bottom: 10rem;
}
.app-others-item-container {
  display: block;
  // border-bottom: 1px solid #2b323d;
  height: 65px;
  width: 100%;
}

.app-others-item {
  display: flex;
  align-items: top;
}

.app-others-tools-list {
  padding-left: 3rem;
  padding-bottom: 0.5rem;
  padding-right: 3rem;
}

//
// Carbon Overrides

.bx--accordion__title {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0rem;
  font-family: "Uber Move", sans-serif, "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif";

  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
  text-align: left;
  padding: 0, 0;
  margin: 0, 0;
  margin-left: 0rem;
}

.bx--accordion__item:last-child {
  border-bottom: none;
  border-top: none;
}

.bx--accordion__item--active .bx--accordion__arrow {
  fill: #ffffff;
}
.bx--accordion__arrow {
  fill: #ffffff;
}

.bx--accordion__heading::before {
  background-color: transparent;
}
.bx--accordion:hover {
  background-color: transparent;
}

.bx--accordion__heading {
  min-height: 0rem;
  height: 2rem;
  //   padding-top: 0;
  //   background-color: green;
}

// Footer

.footer-container {
  height: 400px;
  padding-left: 100px;
  padding-right: 100px;
}

.footer-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-contact-section {
  margin-top: 2rem;
  justify-content: center;
  width: 20%;
  margin: auto;
}
.footer-contact-section-logo {
  margin-top: 0.8rem;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}

.footer-contact-section h4 {
  font-family: "Uber Move", sans-serif;
  font-size: 0.8rem;
  color: #539bf5;
  margin-right: 0.5rem;
  margin-bottom: 0;
  text-align: center;
}

// Media Queries

// Small Mobile devices
@media (min-width: 0px) and (max-width: 599px) {
  .home-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .footer-container {
    margin-top: 4rem;
    height: 250px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .footer-content {
    border-left: none;
    border-right: none;
  }
  .footer-contact-section {
    width: 80%;
    margin: auto;
    height: 80%;
  }
  .footer-contact-section-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
  .footer-section-contact {
    margin-bottom: 0.5rem;
  }

  .home-content {
    border: none;
    padding: 0;
  }

  .home-sections {
    width: 85%;
  }
  .about-content-experience,
  .about-content-technologies,
  .blog-content-featured,
  .blog-categories,
  .app-content-featured,
  .app-categories {
    display: block;
  }

  .about-content-title,
  .blog-content-featured-title,
  .blog-categories-title,
  .app-content-featured-title,
  .app-categories-title {
    margin-bottom: 2rem;
    margin-top: 6rem;
  }

  .landing-content {
    width: 100%;
    height: 700px;
    padding-top: 100px;
  }
  .landing-content h1 {
    font-size: 5rem;
    line-height: 1;
  }
  .landing-content h2 {
    font-size: 1rem;
    line-height: 3rem;
  }

  .contact-section-logo-item {
    margin-top: 0.5rem;
  }

  .contact-section-logo {
    display: block;

    max-width: 100%;
  }
  .blog-content-featured-container-item {
    display: block;
    margin-bottom: 8rem;
    // min-height: 400px;
  }
  .blog-content-featured-image {
    width: 100%;
    height: 250px;
  }

  .blog-content-featured-description {
    min-width: 200px;
    height: 300px;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .blog-content-featured img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-content-featured-logo {
    display: flex;
    margin-top: 1rem;
  }

  .blog-categories-container {
    display: block;
  }

  .app-others-container {
    display: block;
  }
  .about-content-technologies-container {
    flex-basis: 75%;
    display: block;
  }
  .about-technologies-item-container {
    min-height: 65px;
    margin-bottom: 1rem;
  }

  .section-divider {
    margin-bottom: 1rem;
  }

  .blog-content-featured-title {
    margin-top: 1rem;
  }
  .app-content-featured-title {
    margin-top: 1rem;
  }

  .about-content-title {
    margin-top: 1rem;
  }

  .see-all-container a {
    font-size: 0.8em;
  }
  .see-all-container-logo-large {
    display: none;
  }
  .see-all-container-logo-small {
    display: block;
  }
}

// Small - Medium devices
@media (min-width: 600px) and (max-width: 634px) {
  .home-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .footer-container {
    margin-top: 4rem;
    padding-left: 0px;
    padding-right: 0px;
  }

  .footer-content {
    border-left: none;
    border-right: none;
  }
  .footer-contact-section {
    width: 80%;
    margin: auto;
    height: 80%;
  }

  .footer-contact-section-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
  .home-content {
    border: none;
    padding: 2rem;
  }

  .home-sections {
    width: 95%;
  }
  .about-content-experience,
  .about-content-technologies,
  .blog-content-featured,
  .blog-categories,
  .app-content-featured,
  .app-categories {
    display: block;
  }

  .about-content-title,
  .blog-content-featured-title,
  .blog-categories-title,
  .app-content-featured-title,
  .app-categories-title {
    margin-bottom: 2rem;
    margin-top: 6rem;
  }

  .landing-content {
    width: 100%;

    height: 700px;
    padding-top: 100px;
  }
  .landing-content h1 {
    font-size: 5rem;
    line-height: 1;
  }

  .landing-content h2 {
    font-size: 1rem;
  }

  .blog-content-featured-container-item {
    display: block;
    margin-bottom: 8rem;
    // min-height: 400px;
  }
  .blog-content-featured-image {
    width: 100%;
    height: 250px;
  }

  .blog-content-featured-description {
    min-width: 200px;
    height: 300px;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .blog-content-featured img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-content-featured-logo {
    display: flex;
    margin-top: 1rem;
  }
  .about-content-technologies-container {
    flex-basis: 75%;
    display: block;
  }
  .about-technologies-item-container {
    min-height: 65px;
    margin-bottom: 1rem;
  }
}

// Small - Medium devices
@media (min-width: 635px) and (max-width: 819px) {
  .home-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .footer-container {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 4rem;
  }
  .footer-content {
    border-left: none;
    border-right: none;
  }

  .footer-contact-section {
    width: 80%;
    margin: auto;
    height: 80%;
  }

  .footer-contact-section-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
  .home-content {
    border: none;
    padding: 3rem;
  }

  .home-sections {
    width: 95%;
  }
  .about-content-experience,
  .about-content-technologies,
  .blog-content-featured,
  .blog-categories,
  .app-content-featured,
  .app-categories {
    display: block;
  }

  .about-content-title,
  .blog-content-featured-title,
  .blog-categories-title,
  .app-content-featured-title,
  .app-categories-title {
    margin-bottom: 2rem;
    margin-top: 6rem;
  }

  .landing-content {
    width: 100%;
    height: 700px;
    padding-top: 100px;
  }
  .landing-content h1 {
    font-size: 5rem;
    line-height: 1;
  }

  .landing-content h2 {
    font-size: 1.5rem;
  }

  .blog-content-featured-container-item {
    display: block;
    margin-bottom: 8rem;
    // min-height: 400px;
  }
  .blog-content-featured-image {
    width: 100%;
    height: 250px;
  }

  .blog-content-featured-description {
    min-width: 200px;
    height: 300px;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .blog-content-featured img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-content-featured-logo {
    display: flex;
    margin-top: 1rem;
  }
  .about-content-technologies-container {
    flex-basis: 75%;
    display: block;
  }
  .about-technologies-item-container {
    min-height: 65px;
    margin-bottom: 1rem;
  }
}

// Big phones and Small Tablets
@media (min-width: 820px) and (max-width: 944px) {
  .home-container {
    padding-left: 0px;
    padding-right: 0px;
  }
  .footer-container {
    margin-top: 4rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  .footer-content {
    border-left: none;
    border-right: none;
  }

  .home-content {
    border: none;
  }

  .home-sections {
    width: 85%;
  }
  .about-content-experience,
  .about-content-technologies,
  .blog-content-featured,
  .blog-categories,
  .app-content-featured,
  .app-categories {
    display: block;
  }

  .about-content-title,
  .blog-content-featured-title,
  .blog-categories-title,
  .app-content-featured-title,
  .app-categories-title {
    margin-bottom: 2rem;
    margin-top: 6rem;
  }

  .landing-content {
    width: 100%;
    height: 800px;
    padding-top: 100px;
  }
  .landing-content h1 {
    font-size: 6rem;
    line-height: 1;
  }

  .blog-content-featured-container-item {
    display: block;
    margin-bottom: 8rem;
    // min-height: 400px;
  }
  .blog-content-featured-image {
    width: 100%;
    height: 250px;
  }

  .blog-content-featured-description {
    min-width: 200px;
    height: 300px;
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .blog-content-featured img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .blog-content-featured-logo {
    display: flex;
    margin-top: 1rem;
  }
}

// Tablets  and small screens
@media (min-width: 945px) and (max-width: 1199px) {
  .home-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .footer-container {
    margin-top: 4rem;
    padding-left: 0px;
    padding-right: 0px;
  }
  .footer-content {
    border-left: none;
    border-right: none;
  }

  .home-content {
    border: none;
  }

  .home-sections {
    width: 85%;
  }
  .about-content-experience,
  .about-content-technologies,
  .blog-content-featured,
  .blog-categories,
  .app-content-featured,
  .app-categories {
    display: block;
  }

  .about-content-title,
  .blog-content-featured-title,
  .blog-categories-title,
  .app-content-featured-title,
  .app-categories-title {
    margin-bottom: 2rem;
    margin-top: 6rem;
  }

  .landing-content {
    width: 100%;
    height: 800px;
    padding-top: 100px;
  }
  .landing-content h1 {
    font-size: 6rem;
    line-height: 1;
  }
}

// Tablets Landscape and Medium  Screens
@media (min-width: 1200px) and (max-width: 1440px) {
  .home-container {
    padding-left: 60px;
    padding-right: 60px;
  }
  .footer-container {
    padding-left: 60px;
    padding-right: 60px;
    margin-top: 4rem;
  }
  // .home-content {
  // }

  .home-sections {
    width: 85%;
  }
}

// Large - Extra Large Screens
@media (min-width: 1441px) and (max-width: 1820px) {
  .home-container {
    padding-left: 80px;
    padding-right: 80px;
  }
  .footer-container {
    padding-left: 80px;
    padding-right: 80px;
    margin-top: 4rem;
  }

  // .home-content {
  // }

  .home-sections {
    width: 70%;
  }
}
